import React from "react";
import * as styles from "./navbar.module.css";
import { Link } from "gatsby";
import Logo from "./Logo";
import Banner from "./../banner/Banner";

const Navbar = () => {
  return (
    <>
      <Banner />
      <header class="antialiased pt-10">
        <nav class="">
          <div class="flex flex-wrap justify-between items-center">
            <div class="flex justify-start items-center px-4 py-2">
              <Link to="/" title="Home">
                <div className={styles.logo}>
                  <Logo></Logo>
                  <h2>Sleepless Yogi</h2>
                </div>
              </Link>
            </div>
            <div
              className={`navbar flex items-center lg:order-2 border-t border-b lg:border-0 md:border-0 border-purple-500 py-2`}
            >
              <ul class="flex items-center lg:order-2">
                <li className="px-4 font-medium hover:text-violet-600">
                  <Link
                    to="/posts/huge-list-of-recommended-resources-for-tech/"
                    title="Home"
                  >
                    TOOLS
                  </Link>
                </li>
                <li className="px-4 font-medium hover:text-violet-600">
                  <Link
                    to="https://sleeplessyogi.substack.com/archive"
                    title="Newsletter"
                    target="_blank"
                  >
                    Join 10k+ Subscribers
                  </Link>
                </li>
                <li className="px-4 font-medium hover:text-violet-600">
                  <Link to="/posts/" title="Blog">
                    BLOG
                  </Link>
                </li>
                <li className="px-4 font-medium hover:text-violet-600">
                  <Link to="/advertise/" title="Advertise">
                    ADVERTISE
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
