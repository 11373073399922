// extracted by mini-css-extract-plugin
export const mainSection = "footer-module--mainSection--1m_Dy";
export const contentfooter = "footer-module--contentfooter--3g9Dx";
export const footer_sec1 = "footer-module--footer_sec1--2UiV9";
export const footerlogo = "footer-module--footerlogo--oPD4h";
export const footersubs = "footer-module--footersubs--3Wvb1";
export const button4 = "footer-module--button4--1aZGs";
export const btn4 = "footer-module--btn4--3l7aI";
export const back = "footer-module--back--3MNvG";
export const footerfollow = "footer-module--footerfollow--18uiY";
export const follow_name = "footer-module--follow_name--1bHq9";
export const footer_icons = "footer-module--footer_icons--2N3NZ";
export const footer_below = "footer-module--footer_below--2Mjgx";
export const footer_about = "footer-module--footer_about--3buk5";
export const footer_explore = "footer-module--footer_explore--1NT-a";
export const footer_contact = "footer-module--footer_contact--2tkFi";
export const explore_ul = "footer-module--explore_ul--1Jh0F";
export const explore_icon = "footer-module--explore_icon--2_Yjg";
export const footer_contact_info = "footer-module--footer_contact_info--1qyIE";
export const explore_ul1 = "footer-module--explore_ul1--1J_ZC";
export const footer_email = "footer-module--footer_email--234OV";