import React from "react";
import * as styles from "./footer.module.css";
import { Link } from "gatsby";
import { GrFacebookOption } from "@react-icons/all-files/gr/GrFacebookOption";
import { GrTwitter } from "@react-icons/all-files/gr/GrTwitter";
import { GrLinkedinOption } from "@react-icons/all-files/gr/GrLinkedinOption";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { MdPhone } from "@react-icons/all-files/md/MdPhone";
import { IoLocationSharp } from "@react-icons/all-files/io5/IoLocationSharp";
import { GrMail } from "@react-icons/all-files/gr/GrMail";
import { FaPlay } from "@react-icons/all-files/fa/FaPlay";
import Subscribe from "./../subscribe/Subscribe";

const Footer = () => {
  return (
    <div className={styles.mainSection}>
      <div className={styles.contentfooter}>
        <div className={styles.footer_sec1}>
          <div className={styles.footerlogo}>
            {/* <a href="/" title="Sleepless Yogi">
              <p>Sleepless Yogi</p>
            </a> */}
            <Link to="/" title="Sleepless Yogi">
              <p>Sleepless Yogi</p>
            </Link>
          </div>

          <div className={styles.footersubs}>
            <Subscribe></Subscribe>
          </div>

          <div className={styles.footerfollow}>
            <p className={styles.follow_name}>Follow me:</p>

            <div
              className={`flex items-center justify-center ${styles.footer_icons}`}
            >
              {/* <a href="https://twitter.com/sleeplessyogi"
                target="_blank"
                title="Instagram">
                <GrFacebookOption />
              </a> */}
              <a
                href="https://twitter.com/sleeplessyogi"
                target="_blank"
                title="Twitter"
              >
                <GrTwitter />
              </a>
              <a
                href="https://www.instagram.com/sleeplessyogi"
                target="_blank"
                title="Instagram"
              >
                <FaInstagram />
              </a>
              <a
                href="https://linkedin.com/in/sleeplessyogi"
                target="_blank"
                title="LinkedIn"
              >
                <GrLinkedinOption />
              </a>
              <a
                href="mailto:sleeplessyogi@gmail.com"
                target="_blank"
                title="Email"
              >
                <GrMail />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.footer_below}>
          <div className={styles.footer_about}>
            <Link to="/about/" title="About me">
              <h3>About</h3>
            </Link>
            <p>
              Hi - I am Sleepless Yogi. I teach solopreneurs ways to make more
              money without leaving their full-time jobs.
            </p>
          </div>
          <div className={styles.footer_explore}>
            <h3>Explore</h3>
            <div className={styles.explore_ul}>
              <ul className={styles.explore_ul1}>
                <li>
                  <Link
                    className={`flex items-center justify-left`}
                    to="/"
                    title="Home"
                  >
                    <FaPlay className={styles.explore_icon} />
                    <span>Home</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`flex items-center justify-left`}
                    to="/posts/"
                    title="Blog"
                  >
                    <FaPlay className={styles.explore_icon} />
                    <span>Blog</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`flex items-center justify-left`}
                    to="/about/"
                    title="About"
                  >
                    <FaPlay className={styles.explore_icon} />
                    <span>About</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`flex items-center justify-left`}
                    to="/posts/affiliate-partner/"
                    title="Become Affiliate Partner"
                  >
                    <FaPlay className={styles.explore_icon} />
                    <span>Affiliate Partner</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`flex items-center justify-left`}
                    to="/posts/make-money-on-instagram-as-programmer-ultimate-guide-free-lessons/"
                    title="Instagram Money for Programmers"
                  >
                    <FaPlay className={styles.explore_icon} />
                    <span>Online Money for Programmers</span>
                  </Link>
                </li>
              </ul>
              <ul className={styles.explore_ul1}>
                <li>
                  <Link
                    className={`flex items-center justify-left`}
                    to="/apps/"
                    title="Fun Apps"
                  >
                    <FaPlay className={styles.explore_icon} />
                    <span>Fun Apps</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`flex items-center justify-left`}
                    to="/courses/"
                    title="Courses"
                  >
                    <FaPlay className={styles.explore_icon} />
                    <span>Courses</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`flex items-center justify-left`}
                    to="/newsletter/"
                    title="Newsletter"
                  >
                    <FaPlay className={styles.explore_icon} />
                    <span>Newsletter</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`flex items-center justify-left`}
                    to="/posts/huge-list-of-recommended-resources-for-tech/"
                    title="Recommended Resources"
                  >
                    <FaPlay className={styles.explore_icon} />
                    <span>Resources and Tools</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`flex items-center justify-left`}
                    to="/posts/html-to-react-the-ultimate-guide-free-lessons/"
                    title="HTML to React free sample"
                  >
                    <FaPlay className={styles.explore_icon} />
                    <span>HTML to React</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`flex items-center justify-left`}
                    to="/calculators/"
                    title="Free Online Calculators"
                  >
                    <FaPlay className={styles.explore_icon} />
                    <span>Free Online Calculators</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.footer_contact}>
            <h3>Contact me</h3>
            <div className={styles.footer_contact_info}>
              <a
                href="https://www.instagram.com/sleeplessyogi"
                target="_blank"
                title="Instagram"
              >
                <span>
                  <FaInstagram />
                </span>
                <p>Instagram</p>
              </a>
            </div>
            <div className={styles.footer_contact_info}>
              <a
                href="https://twitter.com/sleeplessyogi"
                target="_blank"
                title="Twitter"
              >
                <span>
                  <GrTwitter />
                </span>
                <p>Twitter</p>
              </a>
            </div>
            <div className={styles.footer_contact_info}>
              <a
                href="mailto:sleeplessyogi@gmail.com"
                target="_blank"
                title="Email"
              >
                <span>
                  <GrMail />
                </span>
                <p>sleeplessyogi@gmail.com</p>
              </a>
            </div>
            <div className="mt-4">
              <Link
                className={`flex items-center justify-left`}
                to="/contact-us/"
                title="Contact Us"
              >
                <FaPlay className={styles.explore_icon} />
                <span>Contact Us</span>
              </Link>
            </div>
            <div className="mt-4">
              <Link
                className={`flex items-center justify-left`}
                to="/disclaimer/"
                title="Disclaimer"
              >
                <FaPlay className={styles.explore_icon} />
                <span>Disclaimer</span>
              </Link>
            </div>
            <div className="mt-4">
              <Link
                className={`flex items-center justify-left`}
                to="/terms-and-conditions/"
                title="Terms And Conditions"
              >
                <FaPlay className={styles.explore_icon} />
                <span>Terms And Conditions</span>
              </Link>
            </div>
            <div className="mt-4">
              <Link
                className={`flex items-center justify-left`}
                to="/privacy-policy/"
                title="Privacy Policy"
              >
                <FaPlay className={styles.explore_icon} />
                <span>Privacy Policy</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
