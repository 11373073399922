import React from "react";
import * as styles from "./logo.module.css";
import logo from "../../../images/logo.png";

const Logo = () => {
  return (
    <div className={styles.logo}>
      <img src={logo} alt="logo" />
    </div>
  );
};

export default Logo;
